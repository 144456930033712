<template>
	<main>
		<form @submit.prevent="submitListener">
			<section id="chapters">
				<div class="flex items-center gap-5">
					<div v-for="(chap, key) in CHAPTERS" :key="key" class="chapter-wrapper" @click="changeChapter(chap)">
						<div class="chapter-circle" :class="{'active': chap === chapter}">
							<span class="chapter-text">{{ chapterNumber(chap) }}</span>
						</div>
						<div class="chapter-line" v-if="chapterNumber(chap) !== chaptersLength()"></div>
					</div>
				</div>
			</section>

			<ActivityApplicationSection id="general" title="Generellt" chapter="1" v-show="chapter === CHAPTERS.GENERAL">
				<template #cta>
					<BaseButton type="button" @click="changeChapter(CHAPTERS.ADDRESS)" :disabled="!generalIsComplete">
						Nästa
					</BaseButton>
				</template>

				<div class="grid grid-cols-3 gap-5">
					<BaseInput type="text" v-model="general.title" required class="col-span-2">
						Titel
					</BaseInput>

					<div></div>

					<BaseSelect v-model="general.customer_id" :options="customers" required>
						Distrikt
					</BaseSelect>

					<BaseSelect v-model="general.operation_id" :options="operations" required>
						Verksamhetsform
					</BaseSelect>

					<div></div>

					<BaseInput type="date" v-model="general.starting_at" required>
						Datum för aktivitet
					</BaseInput>

					<BaseInput type="date" v-model="general.published_at" :max="general.starting_at">
						Publiceringsdatum
					</BaseInput>

					<BaseInput type="date" v-model="general.last_application_at" required :max="general.starting_at">
						Sista anmälningsdag
					</BaseInput>

					<BaseTextarea v-model="general.description" rows="5" class="col-span-3">
						Beskrivning
					</BaseTextarea>
				</div>
			</ActivityApplicationSection>

			<ActivityApplicationSection id="address" title="Adress" chapter="2" v-show="chapter === CHAPTERS.ADDRESS">
				<template #cta>
					<BaseButton type="button" transparent @click="changeChapter(CHAPTERS.GENERAL)">
						Föregående
					</BaseButton>
					<BaseButton type="button" @click="changeChapter(CHAPTERS.ATTENDANCE)" :disabled="!addressIsComplete">
						Nästa
					</BaseButton>
				</template>

				<div class="grid grid-cols-3 gap-5">
					<BaseInput type="text" v-model="address.address" required>
						Adress
					</BaseInput>

					<BaseInput type="text" v-model="address.zipcode" required maxlength="5">
						Postnummer
					</BaseInput>

					<BaseInput type="text" v-model="address.city" required>
						Stad
					</BaseInput>

					<BaseTextarea v-model="address.address_description" rows="5" class="col-span-3">
						Beskrivning
					</BaseTextarea>
				</div>
			</ActivityApplicationSection>

			<ActivityApplicationSection id="attendance" title="Deltagare" chapter="3" v-show="chapter === CHAPTERS.ATTENDANCE">
				<template #cta>
					<BaseButton type="button" transparent @click="changeChapter(CHAPTERS.ADDRESS)">
						Föregående
					</BaseButton>
					<BaseButton type="button" @click="changeChapter(CHAPTERS.CONTACTS)" :disabled="!attendanceIsComplete">
						Nästa
					</BaseButton>
				</template>

				<div class="grid grid-cols-3 gap-5">

					<BaseInput type="number" v-model="attendance.min_applications" step="1" required>
						Minsta antal deltgare
					</BaseInput>

					<BaseInput type="number" v-model="attendance.max_applications" step="1" required>
						Max antal deltagare
					</BaseInput>

					<div></div>

					<BaseCheckbox v-model="attendance.use_verify_application" required>
						Krav att godkänna deltagare
					</BaseCheckbox>

					<BaseCheckbox v-model="attendance.use_spare_applications" required>
						Reservlista används
					</BaseCheckbox>

					<BaseCheckbox v-model="attendance.applications_are_public" required>
						Publicera antal registrerade deltagare
					</BaseCheckbox>
				</div>
			</ActivityApplicationSection>

			<ActivityApplicationSection id="contacts" title="Kontaktuppgifter" chapter="4" v-show="chapter === CHAPTERS.CONTACTS">
				<template #cta>
					<BaseButton type="button" transparent @click="changeChapter(CHAPTERS.ATTENDANCE)">
						Föregående
					</BaseButton>
					<BaseButton type="button" @click="changeChapter(CHAPTERS.EMAIL)" :disabled="!contactsIsComplete">
						Nästa
					</BaseButton>
				</template>

				<div class="grid gap-5">
					<BaseUserSearch v-model="contacts.user" :params="{'customer_id': '1'}" placeholder="Sök ...">
						Sök personnummer, namn eller e-post
					</BaseUserSearch>
				</div>

				<div class="mt-10">
					<table>
						<tr>
							<th class="w-1/4">Namn</th>
							<th class="w-1/4">E-post</th>
							<th class="w-1/4">Telefonnummer</th>
							<th class="w-1/4">Roll</th>
							<th class="w-1/4"></th>
						</tr>

						<tr v-for="(user, index) in users" :key="user.id">
							<td>{{ user.first_name }} {{ user.last_name }}</td>
							<td>{{ user.email }}</td>
							<td>{{ user.telephone }}</td>
							<td class="flex items-center gap-5">
								<BaseRoleSearch
									v-model="user.role"
									:params="{'customer_id': '1'}"
									placeholder="Sök Roll ..."
									pre-fetch
									class="flex-1"
								/>
								<button type="button" @click="removeUserListener(index)">
									<TrashIcon size="20" class="text-red-400"/>
								</button>
							</td>
						</tr>
					</table>
				</div>
			</ActivityApplicationSection>

			<ActivityApplicationSection id="email" title="E-postutskick" chapter="5" v-show="chapter === CHAPTERS.EMAIL">
				<template #cta>
					<BaseButton type="button" transparent @click="changeChapter(CHAPTERS.CONTACTS)">
						Föregående
					</BaseButton>
					<BaseButton type="button" @click="changeChapter(CHAPTERS.PRICE)" :disabled="!emailIsComplete">
						Nästa
					</BaseButton>
				</template>

				<div>
					<BaseTextarea v-model="email.confirmation_text" rows="5" required>
						Meddelande i bokningsbekräftelse
					</BaseTextarea>
				</div>
			</ActivityApplicationSection>

			<ActivityApplicationSection id="price" title="Priser & villkor" chapter="6" v-show="chapter === CHAPTERS.PRICE">
				<template #cta>
					<BaseButton type="button" transparent @click="changeChapter(CHAPTERS.EMAIL)">
						Föregående
					</BaseButton>
					<BaseButton type="button" @click="changeChapter(CHAPTERS.LODGING)" :disabled="!priceIsComplete">
						Nästa
					</BaseButton>
				</template>

				<div v-for="(price, index) in prices" :key="index">
					<div class="flex justify-between items-center mb-10">
						<h5>Pris nr.{{ index + 1 }}</h5>
						<button type="button" @click="removePriceListener(index)">
							<TrashIcon size="20" class="text-red-400"/>
						</button>
					</div>

					<div class="grid grid-cols-3 gap-5">
						<BaseInput type="text" v-model="price.title">
							Titel
						</BaseInput>

						<BaseInput type="number" v-model="price.amount" step="1">
							Pris
						</BaseInput>

						<BaseInput type="date" v-model="price.starting_at">
							Datum då priset börjar gälla
						</BaseInput>

						<BaseTextarea v-model="price.description" rows="5" class="col-span-3">
							Kostnadsbeskrivning
						</BaseTextarea>

						<BaseTextarea v-model="price.cancellation" rows="5" class="col-span-3">
							Avbokningsregler
						</BaseTextarea>
					</div>

					<div class="h-1 bg-primary rounded-full my-10" v-if="prices.length > 1"></div>
				</div>


				<div class="text-right" :class="{'mt-10' : prices.length === 1}">
					<BaseButton type="button" @click="newPriceListener">
						Lägg till nytt pris
					</BaseButton>
				</div>
			</ActivityApplicationSection>

			<ActivityApplicationSection id="lodging" title="Logi & Kost" chapter="7" v-show="chapter === CHAPTERS.LODGING">
				<template #cta>
					<BaseButton type="button" transparent @click="changeChapter(CHAPTERS.PRICE)">
						Föregående
					</BaseButton>
					<BaseButton type="submit" :disabled="!formIsComplete">
						Skapa aktivitet
					</BaseButton>
				</template>

				<div class="grid gap-5">
					<BaseTextarea v-model="lodging.lodging" rows="5" required>
						Logi
					</BaseTextarea>

					<BaseTextarea v-model="lodging.food" rows="5" required>
						Kost
					</BaseTextarea>
				</div>
			</ActivityApplicationSection>
		</form>
	</main>
</template>

<script>
import {mapGetters} from 'vuex'
import {TrashIcon} from '@vue-hero-icons/solid'
import ActivityService from '@/core/services/ActivityService'
import CustomerService from '@/core/services/CustomerService'
import OperationService from '@/core/services/OperationService'
import ActivityApplicationSection from '@/modules/activity/components/ActivityApplicationSection'
import basicApiCall from '@/core/helpers/basicApiCall'

const CHAPTERS = {
	GENERAL: 'GENERAL',
	ADDRESS: 'ADDRESS',
	ATTENDANCE: 'ATTENDANCE',
	CONTACTS: 'CONTACTS',
	EMAIL: 'EMAIL',
	PRICE: 'PRICE',
	LODGING: 'LODGING',
}

export default {
	name: 'ActivityCreate',
	components: {
		TrashIcon,
		ActivityApplicationSection,
	},
	data: () => ({
		CHAPTERS,
		chapter: CHAPTERS.GENERAL,
		general: {
			title: '',
			customer_id: '',
			organisation_id: '',
			operation_id: '',
			starting_at: '',
			published_at: '',
			last_application_at: '',
			description: '',
		},
		address: {
			address: '',
			zipcode: '',
			city: '',
			address_description: '',
		},
		attendance: {
			min_applications: '',
			max_applications: '',
			use_verify_application: false,
			use_spare_applications: false,
			applications_are_public: false,
		},
		contacts: {
			user: '',
		},
		users: [],
		email: {
			confirmation_text: '',
		},
		prices: [],
		lodging: {
			lodging: '',
			food: '',
		},
		customers: [],
		operations: [],
	}),
	computed: {
		...mapGetters({
			user: 'AuthStore/user',
		}),
		generalIsComplete() {
			return this.general.title.length
				&& typeof this.general.customer_id === 'number'
				&& typeof this.general.operation_id === 'number'
				&& this.general.starting_at.length
				&& this.general.published_at.length
				&& this.general.last_application_at.length
		},
		addressIsComplete() {
			return this.address.address.length
				&& this.address.zipcode.length
				&& this.address.city.length
		},
		attendanceIsComplete() {
			return this.attendance.min_applications > 0
				&& this.attendance.max_applications > 0
		},
		contactsIsComplete() {
			return this.users.length
		},
		emailIsComplete() {
			return this.email.confirmation_text.length
		},
		priceIsComplete() {
			if (!this.prices.length) {
				return true
			}

			const completedPrices = this.prices.filter(price => {
				return price.title.length
					&& price.amount > 0
					&& price.starting_at.length
			})

			return completedPrices.length === this.prices.length
		},
		lodgingAndFoodIsComplete() {
			return this.lodging.lodging.length
				&& this.lodging.food.length
		},
		formIsComplete() {
			return this.generalIsComplete
				&& this.addressIsComplete
				&& this.attendanceIsComplete
				&& this.contactsIsComplete
				&& this.emailIsComplete
				&& this.priceIsComplete
				&& this.lodgingAndFoodIsComplete
		},
	},
	watch: {
		'contacts.user'(newValue) {
			if (this.users.findIndex(usr => usr.id === newValue.id) === -1) {
				this.users.push(newValue)
			}
		},
	},
	methods: {
		async loadCustomers() {
			const {data, status} = await basicApiCall(CustomerService.index())
			if (status === 200) {
				this.customers = data
			}
		},
		async loadOperations() {
			const {data, status} = await basicApiCall(OperationService.index())
			if (status === 200) {
				this.operations = data
			}
		},
		changeChapter(chapter) {
			this.chapter = chapter
		},
		chaptersLength() {
			return Object.keys(this.CHAPTERS).length
		},
		chapterNumber(chapter) {
			return Object.keys(this.CHAPTERS).findIndex(c => c === chapter) + 1
		},
		removeUserListener(user_index) {
			this.users.splice(user_index, 1)
		},
		newPriceListener() {
			this.prices.push({
				title: '',
				amount: '',
				starting_at: '',
				description: '',
				cancellation: '',
			})
		},
		removePriceListener(user_index) {
			this.prices.splice(user_index, 1)
		},
		async submitListener() {
			const users = this.users.map(user => ({
				id: user.id,
				role: {id: user.role.id},
			}))

			const entity = {
				...this.general,
				...this.address,
				...this.attendance,
				...this.contacts,
				...this.email,
				...this.lodging,
				prices: this.prices,
				users,
			}

			const {data, status} = await basicApiCall(ActivityService.create(entity))
			if (status === 201) {
				alert('Aktivitet skapades')
				await this.$router.replace(`/activity/${data.id}/general`)
			}
		},
	},
	created() {
		this.loadCustomers()
		this.loadOperations()
	},
}
</script>

<style scoped>
.chapter-wrapper {
	@apply flex gap-5 items-center;
}

.chapter-circle {
	@apply h-10 w-10 bg-gray-300 rounded-full grid place-items-center;
	@apply transition-all duration-500;
}

.chapter-text {
	@apply text-gray-400 font-bold text-sm;
}

.chapter-line {
	@apply h-2 w-12 bg-gray-300 rounded-full;
}

.active {
	@apply bg-primary;
}

.active span {
	@apply text-white;
}
</style>
